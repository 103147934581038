import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles"
// Gatsby components
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
// React icons
import { FaLinkedin } from "react-icons/fa"
// core components
import Section from "components/appComponents/Section/Section"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Button from "components/CustomButtons/Button"
import CardBody from "components/appComponents/Card/CardBody"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Close from "@material-ui/icons/Close"
import Slide from "@material-ui/core/Slide"
// Style
import style from "assets/jss/app/views/teamStyle"

import CardGatsbyBgImg from "components/appComponents/Card/CardGatsbyBgImg"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

Transition.displayName = "Transition"

const TeamSection = withStyles(style)((props) => {
  const { classes } = props
  const [dialogOpen, setDialogOpen] = React.useState(null)

  const data = useStaticQuery(graphql`
    query {
      team: allMdx(filter: { fileAbsolutePath: { regex: "/content/team/" } }) {
        nodes {
          id
          body
          frontmatter {
            name
            title
            linkedin
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 40
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Section title="Our team" id="team">
      {/*<Section title="Our team" id="team" color="primary">*/}
      <GridContainer>
        {data.team.nodes.map((profile) => {
          const image = getImage(profile.frontmatter.imageFile)
          return (
            <GridItem xs={12} sm={12} md={6} lg={4}>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault()
                  setDialogOpen(profile.id)
                }}
              >
                <CardGatsbyBgImg image={image} raised darken>
                  <CardBody className={classes.cardBody}>
                    <h3 className={classes.frontName}>
                      {profile.frontmatter.name}
                    </h3>
                    <h6 className={classes.cardTitle}>
                      {profile.frontmatter.title}
                    </h6>
                  </CardBody>
                </CardGatsbyBgImg>
              </a>
              {/* MODAL START */}
              <Dialog
                classes={{
                  root: classes.modalRoot,
                  container: classes.modalContainer,
                  paper: classNames(classes.modal, classes.modalLarge),
                }}
                open={profile.id === dialogOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={setDialogOpen}
                aria-labelledby={"modal-title-" + profile.id}
                aria-describedby={"modal-content-" + profile.id}
              >
                <DialogTitle
                  id={"modal-title-" + profile.id}
                  disableTypography
                  className={classes.modalHeader}
                >
                  <Button
                    simple
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    onClick={setDialogOpen}
                  >
                    {" "}
                    <Close className={classes.modalClose} />
                  </Button>
                  <h3 className={classes.modalTitle}>
                    {profile.frontmatter.name}
                  </h3>
                </DialogTitle>
                <DialogContent
                  id={"modal-content-" + profile.id}
                  className={classes.cardContent}
                >
                  <GridContainer>
                    <GridItem xs={12} sm={4} md={4}>
                      <GatsbyImage
                        image={image}
                        alt={profile.frontmatter.name}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={8} md={8}>
                      <MDXRenderer>{profile.body}</MDXRenderer>
                    </GridItem>
                  </GridContainer>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                  <Button
                    justIcon
                    color="transparent"
                    target="_blank"
                    href={profile.frontmatter.linkedin}
                  >
                    <FaLinkedin />
                  </Button>
                </DialogActions>
              </Dialog>
              {/* MODAL END */}
            </GridItem>
          )
        })}
      </GridContainer>
    </Section>
  )
})

export default TeamSection
