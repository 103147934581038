import {
  cardCenterHorizVert,
  hexToRgb,
  whiteColor,
} from "assets/jss/material-kit-pro-react.js"

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js"

const styles = (theme) => ({
  ...modalStyle(theme),
  cardBody: {
    ...cardCenterHorizVert,
    paddingTop: "240px",
    minHeight: "380px",
  },
  cardName: {
    color: whiteColor + " !important",
  },
  cardTitle: {
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.7)",
  },
  dialogActions: {
    justifyContent: "center",
  },
})

export default styles
